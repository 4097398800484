<script setup>
import { Authenticator } from '@aws-amplify/ui-vue';
import { Auth } from 'aws-amplify';
const formFields = {
  signIn: {
    username: {
      labelHidden: true,      
      isRequired: true,
      label: ""      
    },
  },
  signUp: {
    email: {
      labelHidden: true,      
      isRequired: true,
      label: "",
      order: 1    
    },
    password: {
      order: 2
    },
    confirm_password: {
      order: 3
    },
    name: {
      order: 4,
      label: ""      
    }
  },
}
</script>
<template>
  <button v-on:click="auth" class="btn">
                    <img src="img/google-signin.png"/>  
                  </button>
<!--<authenticator :form-fields="formFields">
    <template v-slot="{ user, signOut }">                    
    <button @click="signOut">Se déconnecter</button>      
    <div class="calendly-calendar">  
      <div class="calendly-inline-widget" 
      :data-url="`https://calendly.com/r-lefrancois/test-event?hide_gdpr_banner=1&hide_event_type_details=1&hide_landing_page_details=1&email=${user.attributes.email}&name=${user.attributes.name}`"
      style="min-width:300px;height:1200px;"></div>
    <component v-bind:is="'script'" src="https://assets.calendly.com/assets/external/widget.js" async></component>    
    </div>       
    
    </template>
    
  </authenticator>-->
  <!--<Form @submit="onSubmit" :validation-schema="schema">
    <div class="col-md-4 ml-auto mr-auto">
      <vnud-card class="card-login card-plain">
        <template v-slot:header>
          <div>
            <div class="logo-container">
              <img src="img/now-logo.png" alt="" />
            </div>
          </div>
        </template>

        <div>
          <vee-input
            name="email"
            type="text"
            inputClasses="no-border form-control-lg"
            placeholder="Email"
            addon-left-icon="now-ui-icons ui-1_email-85"
          />

          <vee-input
            name="password"
            type="text"
            inputClasses="no-border form-control-lg"
            placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />
        </div>

        <template v-slot:footer>
          <div>
            <n-button native-type="submit" type="primary" round block>
              Get Started
            </n-button>
            <div class="pull-left">
              <h6>
                <router-link class="link footer-link" to="/register">
                  Create Account
                </router-link>
              </h6>
            </div>

            <div class="pull-right">
              <h6>
                <a href="javascript:;" class="link footer-link">Need Help?</a>
              </h6>
            </div>
          </div>
        </template>
      </vnud-card>
    </div>
  </Form>-->
</template>
<script>
import { VeeInput } from "@/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
export default {
  name: "vnud-login",
  components: {
    //VeeInput,
    //Form,
  },
  data(){
    return {
      email: '',
      password:'',
      name: ''
    }
  },
  methods: {
    auth() {
    try {
        Auth.federatedSignIn({ provider: 'Google' });
    } catch (error) {
        console.log('error:', error);
    }
  },
  async signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}
},
  setup() {
    function onSubmit(values) {
      alert(JSON.stringify(values, null, 2));
    }

    const schema = Yup.object().shape({
      email: Yup.string().email().required().label("The Email"),
      password: Yup.string().min(6).required().label("The Password"),
    });

    return {
      onSubmit,
      schema,
    };
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

</style>
<style scoped>
input { 
  margin-right: 10px; 
}
</style>
