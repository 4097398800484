/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getValdTrial = /* GraphQL */ `
  query GetValdTrial($id: ID!) {
    getValdTrial(id: $id) {
      id
      valdTrialId
      valdAthleteId
      valdHubAthleteId
      recordedUTC
      recordedOffset
      recordedTimezone
      startTime
      endTime
      results {
        resultId
        value
        time
        limb
        repeat
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listValdTrials = /* GraphQL */ `
  query ListValdTrials(
    $filter: ModelValdTrialFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValdTrials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        valdTrialId
        valdAthleteId
        valdHubAthleteId
        recordedUTC
        recordedOffset
        recordedTimezone
        startTime
        endTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncValdTrials = /* GraphQL */ `
  query SyncValdTrials(
    $filter: ModelValdTrialFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncValdTrials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        valdTrialId
        valdAthleteId
        valdHubAthleteId
        recordedUTC
        recordedOffset
        recordedTimezone
        startTime
        endTime
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getValdTest = /* GraphQL */ `
  query GetValdTest($id: ID!) {
    getValdTest(id: $id) {
      id
      valdTestId
      valdTeamId
      valdAthleteId
      valdHubId
      recordingId
      recordedUTC
      recordedOffset
      recordedTimezone
      analysedUTC
      analysedOffset
      analysedTimezone
      valdLastModifiedUTC
      testType
      param {
        resultID
        result
        value
      }
      weight
      links
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listValdTests = /* GraphQL */ `
  query ListValdTests(
    $filter: ModelValdTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValdTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        valdTestId
        valdTeamId
        valdAthleteId
        valdHubId
        recordingId
        recordedUTC
        recordedOffset
        recordedTimezone
        analysedUTC
        analysedOffset
        analysedTimezone
        valdLastModifiedUTC
        testType
        weight
        links
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncValdTests = /* GraphQL */ `
  query SyncValdTests(
    $filter: ModelValdTestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncValdTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        valdTestId
        valdTeamId
        valdAthleteId
        valdHubId
        recordingId
        recordedUTC
        recordedOffset
        recordedTimezone
        analysedUTC
        analysedOffset
        analysedTimezone
        valdLastModifiedUTC
        testType
        weight
        links
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getValdAthlete = /* GraphQL */ `
  query GetValdAthlete($id: ID!) {
    getValdAthlete(id: $id) {
      id
      valdAthleteId
      valdTeamId
      valdHubId
      name
      givenName
      familyName
      valdLastModifiedUTC
      attributes {
        attributeValueId
        valueName
        attributeTypeId
        typeName
      }
      links
      externalId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listValdAthletes = /* GraphQL */ `
  query ListValdAthletes(
    $filter: ModelValdAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listValdAthletes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        valdAthleteId
        valdTeamId
        valdHubId
        name
        givenName
        familyName
        valdLastModifiedUTC
        links
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncValdAthletes = /* GraphQL */ `
  query SyncValdAthletes(
    $filter: ModelValdAthleteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncValdAthletes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        valdAthleteId
        valdTeamId
        valdHubId
        name
        givenName
        familyName
        valdLastModifiedUTC
        links
        externalId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      age
      sex
      sport
      phone
      city
      street
      postalCode
      username
      email
      meta
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        age
        sex
        sport
        phone
        city
        street
        postalCode
        username
        email
        meta
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        firstName
        lastName
        age
        sex
        sport
        phone
        city
        street
        postalCode
        username
        email
        meta
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
