/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:aa8f1213-c953-4cf5-96a1-0604291d5d37",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_01sZf1SSS",
    "aws_user_pools_web_client_id": "6hl1u3n4s96lcvtgaoaqrvmp1g",
    "oauth": {
        "domain": "combatvue8ffd0fd7-8ffd0fd7-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://main.d3l12n6cq8lbkq.amplifyapp.com/,http://localhost:8080/,https://combineforathlete.com/",
        "redirectSignOut": "https://main.d3l12n6cq8lbkq.amplifyapp.com/,http://localhost:8080/,https://combineforathlete.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "api7d8a2781",
            "endpoint": "https://ralydmz1z0.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://cdmprcthq5eajgi2sldoq4no6u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7leb7t7qzjgh3ffficjrw7ci7m"
};


export default awsmobile;
