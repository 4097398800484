import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import DashboardPlugin from "./dashboard-plugin";
import { Amplify} from 'aws-amplify';
import awsExports from './aws-exports';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


if (process.env.VUE_APP_ENV === "dev"){
    awsExports.oauth.redirectSignIn = "http://localhost:8080/"
    awsExports.oauth.redirectSignOut = "http://localhost:8080/"
}
else if (process.env.VUE_APP_AWS_BRANCH === "main") {
    awsExports.oauth.redirectSignIn = "https://combineforathlete.com/"
    awsExports.oauth.redirectSignOut = "https://combineforathlete.com/"
}
else {
    awsExports.oauth.redirectSignIn = "https://" + process.env.VUE_APP_AWS_BRANCH + ".d3l12n6cq8lbkq.amplifyapp.com/"
    awsExports.oauth.redirectSignOut = "https://" + process.env.VUE_APP_AWS_BRANCH + ".d3l12n6cq8lbkq.amplifyapp.com/"
}

Amplify.configure(awsExports);

const appInstance = createApp(App);

const options = { containerClassName: "ct-notification" };
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(DashboardPlugin);
appInstance.mount("#app");
