<script setup>
import { Auth } from 'aws-amplify';
</script>

<template>  
    <div class="row">
      <div class="col-lg-5 ml-auto">
        <div class="info-area info-horizontal mt-5">
          <div class="icon icon-primary">
            <i class="now-ui-icons media-2_sound-wave"></i>
          </div>
          <div class="description">
            <h5 class="info-title">Marketing</h5>
            <p class="description">
              We've created the marketing campaign of the website. It was a very
              interesting collaboration.
            </p>
          </div>
        </div>

        <div class="info-area info-horizontal">
          <div class="icon icon-primary">
            <i class="now-ui-icons media-1_button-pause"></i>
          </div>
          <div class="description">
            <h5 class="info-title">Fully Coded in HTML5</h5>
            <p class="description">
              We've developed the website with HTML5 and CSS3. The client has
              access to the code using GitHub.
            </p>
          </div>
        </div>

        <div class="info-area info-horizontal">
          <div class="icon icon-info">
            <i class="now-ui-icons users_single-02"></i>
          </div>
          <div class="description">
            <h5 class="info-title">Built Audience</h5>
            <p class="description">
              There is also a Fully Customizable CMS Admin Dashboard for this
              product.
            </p>
          </div>
        </div>
      </div>      
      <div class="col-lg-4 mr-auto">
        <vnud-card class="card-signup text-center" no-footer-line>
          <template v-slot:header>
            <h4 class="card-title">Login</h4>
            <div class="social">       
              <button v-on:click ="googleAuth" class="btn btn-icon btn-round btn-google">              
                <i class="fa fa-google"></i>
              </button> 
              Google

              <!--<button class="btn btn-icon btn-round btn-twitter">
                <i class="fa fa-twitter"></i>
              </button>-->
              <button v-on:click ="appleAuth" class="btn btn-icon btn-round btn-apple">
                <i class="fa fa-apple"></i>
              </button>
              Apple
              <button v-on:click ="facebookAuth" class="btn btn-icon btn-round btn-facebook">
                <i class="fa fa-facebook"> </i>
              </button>  
              Facebook            
              
            </div>
          </template>
 

          <template v-slot:footer>
          </template>
        </vnud-card>
      </div>
    </div>  
</template>
<script>
import * as Yup from "yup";
export default {
  name: "vnud-register",
  components: {        
  },
  data() {
    return {
      agree: false,
    };
  },
  setup() {
    function onSubmit(values) {
      alert(JSON.stringify(values, null, 2));
    }

    const schema = Yup.object().shape({
      firstName: Yup.string().required().label("The First Name"),
      lastName: Yup.string().required().label("The Last Name"),
      email: Yup.string().email().required().label("The Email"),
    });
    

    return {
      onSubmit,
      schema      
    };
  },
  methods: {  
    googleAuth() {      
      try {
          Auth.federatedSignIn({ provider: 'Google' });
      } catch (error) {
          console.log('error:', error);
      }
    },
    facebookAuth() {      
      try {
          Auth.federatedSignIn({ provider: 'Facebook' });
      } catch (error) {
          console.log('error:', error);
      }
    },
    appleAuth() {      
      try {
          Auth.federatedSignIn({ provider: 'SignInWithApple' });
      } catch (error) {
          console.log('error:', error);
      }
    } 
  }
};
</script>
<style></style>
